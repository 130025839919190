// LandingPage.vue
<template>
  <v-container>
    <v-row justify="center" align="center" class="hero">

      <v-col cols="12" sm="8" md="12">
        <transition-group name="fade-transition">
          <img :src="require('./../assets/logo_y_slogan_blanco.png')" class="mt-12" key="img-logo-y-slogan"
            alt="Sportfit app logo" width="300px" style="display:block;margin:auto;" />
          <!--           <img :src="require('./../assets/app.png')" key="app" alt="Sportfit aplicación" width="300px" class="imgLanding"  style="display: block;margin: auto;"/>
 -->

          <!--           <h2 key="title1" class="title1 text-center display-2 font-weight-bold mb-4 mt-5 text-h4">Ponte en Forma con Nuestra App de Entrenamiento en el Gimnasio</h2>
 -->
          <h2 key="title2" class="title2 text-center subtitle-1 my-12 text-h5">Entrenamientos personalizados,
            seguimiento de progreso y más!</h2>

          <div key="buttons1" class="text-center">


            <v-btn class="mb-5" large block v-if="showInstallButton" @click="addToHomeScreen" rounded>
              <v-icon left>mdi-plus</v-icon>
              Agregar a la pantalla de inicio
            </v-btn>

            <v-btn class="mb-5" large block v-if="showInstallPrompt" @click="installPWA" rounded>
              <v-icon left>mdi-download</v-icon>
              Instalar aplicación
            </v-btn>

            <!--   <v-btn large dark color="#0ab1ec" class="mr-5" @click="$router.replace('/signup')">
              
              <v-icon left>mdi-account-plus</v-icon>Comezar 
          </v-btn> -->


            <v-btn x-large @click="$router.push('/login')" color="primary" rounded>
              <v-icon left>
                mdi-login
              </v-icon>
              Iniciar sesion</v-btn>

          </div>
        </transition-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "LandingPage",
  data() {
    return {
      showInstallPrompt: false,
      deferredPrompt: null,
    }
  },
  methods: {
    async installPWA() {
      if (this.deferredPrompt) {
        this.showInstallPrompt = false;
        this.deferredPrompt.prompt();
        const { outcome } = await this.deferredPrompt.userChoice;
        //console.log(`User response to the install prompt: ${outcome}`);
        this.deferredPrompt = null;
      }
    },
    addToHomeScreen() {
      alert('Para instalar esta aplicación web, toque "Compartir" y luego "Agregar a la pantalla de inicio"')
    },
  },
  mounted() {
    this.$vuetify.goTo(0);
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      this.showInstallPrompt = true;
    });
  },
  computed: {
    isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    isStandalone() {
      return (window.navigator.standalone !== undefined) ? window.navigator.standalone : false;
    },
    showInstallButton() {
      return this.isIOS && !this.isStandalone;
    }
  },
};
</script>

<style scoped>
@media screen and (max-width: 600px) {
  .imgLanding {
    width: 100%;
  }
}

@media screen and (min-width: 600px) {
  .imgLanding {
    width: 500px;
  }
}
</style>